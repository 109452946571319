<template>
  <!-- NAME[epic=设置] 期初建账 -->
  <div class="orderTest-container">
    <div class="wrap">
      <div class="left">
        <el-button-group>
          <el-button
            v-for="(item, index) in data"
            :key="index"
            :type="form.type == index + 1 ? 'primary' : ''"
            @click="navClick(item.id)"
          >
            {{ item.name }}
          </el-button>
        </el-button-group>
      </div>
      <!-- <div v-if="0" class="right">
        <i
          class="el-icon-warning"
          style="
            margin-right: 15px;
            font-size: 22px;
            color: #f60;
            vertical-align: middle;
            cursor: pointer;
          "
          @click="$message('请先选择一个一级科目，再点击添加科目')"
        ></i>
        <el-button type="primary" @click="handleAdd">添加科目</el-button>
      </div> -->
    </div>
    <el-table
      ref="table"
      v-loading="loading"
      stripe
      :data="list"
      style="margin-top: 15px"
      default-expand-all
      row-key="id"
      max-height="600"
      :indent="34"
      :row-class-name="rowClass"
      highlight-current-row
      :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
      @current-change="handleCurrentChange"
    >
      <!-- 序号 -->
      <el-table-column align="center" type="index" width="50">
        <template slot="header">
          <el-popover popper-class="custom-table-checkbox" trigger="hover">
            <el-checkbox-group v-model="checkList">
              <el-checkbox
                v-for="(item, index) in columns"
                :key="index"
                :label="item.label"
              ></el-checkbox>
            </el-checkbox-group>
            <el-button slot="reference" type="text">
              <vab-remix-icon icon="settings-line" />
            </el-button>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column
        v-for="(item, tableIndex) in finallyColumns"
        :key="tableIndex"
        :prop="item.prop"
        :label="item.label"
        width=""
        :type="item.type"
        align="center"
      >
        <!-- <template #default="{ row }">
          <div v-if="item.label == '借贷方向'">
            {{ type }}
          </div>
          <div v-else-if="item.label == '期初金额'">123</div>
          <div v-else>{{ row[item.prop] }}</div>
        </template> -->
      </el-table-column>
      <el-table-column prop="" label="借贷方向" align="center">
        {{ type }}
      </el-table-column>
      <el-table-column prop="" label="期初金额" align="center">
        <template #default="{ row }">
          <div v-if="row.is_has_child == 0">
            <el-input v-model="row.money"></el-input>
          </div>
        </template>
      </el-table-column>
      <!-- <el-table-column
        v-if="shwoCon"
        prop=""
        align="center"
        label="操作"
        width="100"
      >
        <template #default="{ $index, row }">
          <div v-if="row.dealer_id != 0">
            <el-button
              type="text"
              @click.native.prevent="handleEditRow($index, row)"
            >
              编辑
            </el-button>
            <el-button
              type="text"
              @click.native.prevent="handleDeleteRow($index, row)"
            >
              删除
            </el-button>
          </div>
        </template>
      </el-table-column> -->
    </el-table>
    <div class="dialog-footer">
      <el-button @click="handleSunmit(0)">暂存</el-button>
      <el-button type="warning" @click="handleSunmit(2)">试算平衡</el-button>
      <el-button type="primary" @click="handleSunmit(1)">完成建账</el-button>
    </div>
  </div>
</template>
<script>
  import _ from 'lodash'
  import { getList2, deleteRow } from '@/api/setting'
  import { postAction } from '@/api/Employee'
  export default {
    name: 'InitialOrder',
    components: {},
    props: {
      shwoCon: {
        type: Boolean,
        default: () => true,
      },
    },
    data() {
      return {
        data: [],
        loading: false,
        selectRow: '',
        form: {
          pageNo: 1, //
          pageSize: -1, //-1表示不分页
          type: 1, //科目类别，默认是1，1资产类2负债类3权益类4损益类(收入)5损益类(支出)
          depart_id: '', //部门id
        },
        // 科目类别，默认是1，1资产类2负债类3权益类4损益类(收入)5损益类(支出)
        // nav: ['资产类', '负债类', '权益类', '损益类(收入)', '损益类(支出)'],
        checkList: ['科目代码', '科目名称'],
        columns: [
          {
            order: 1,
            label: '科目代码',
            prop: 'bill_code',
            width: '',
            type: '',
          },
          {
            order: 2,
            label: '科目名称',
            prop: 'name',
            width: '',
          },
          // {
          //   order: 3,
          //   label: '借贷方向',
          //   prop: '',
          //   width: '',
          // },
          // {
          //   order: 4,
          //   label: '期初金额',
          //   prop: '',
          //   width: '100',
          // },
        ],
      }
    },
    computed: {
      // 表格数据
      list() {
        let type = this.form.type
        let list = this.data.filter((i) => i.id == type)[0]
        if (list) {
          return list.list
        } else {
          return []
        }
      },
      // 借贷方向
      type() {
        if (this.form.type == 1 || this.form.type == 5) {
          return '借'
        } else {
          return '贷'
        }
      },
      finallyColumns() {
        let finallyArray = []
        this.checkList.forEach((checkItem) => {
          finallyArray.push(
            this.columns.filter((item) => item.label === checkItem)[0]
          )
        })
        return _.sortBy(finallyArray, (item) => item.order)
      },
    },
    watch: {},
    created() {
      this.fetchData()
    },
    mounted() {},
    methods: {
      async fetchData() {
        this.loading = true
        let { data, code, msg, totalCount } = await getList2(this.form)
        console.log(data)
        this.data = data
        this.total = totalCount
        this.loading = false
      },
      navClick(index) {
        if (this.form.type != index) {
          this.form.type = index
          // this.fetchData()
        }
        // /科目类别，默认是1，1资产类2负债类3权益类4损益类(收入)5损益类(支出)
      },

      rowClass({ row, rowIndex }) {
        if (row.level == '1') {
          return 'blue'
        } else {
          return ''
        }
      },
      handleCurrentChange(val) {
        this.selectRow = val
        this.$emit('current-row', val)
      },
      handleSelectRow(a, val) {
        console.log(val)

        this.selectRow = val
      },
      // children 下面的【]
      mapChildren(arr) {
        var data = []
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].children.length != 0) {
            let a = this.mapChildren(arr[i].children)
            data = data.concat(a)
          }
          data.push(arr[i])
        }
        return data
      },
      handleSunmit(type) {
        // /cashAdmin/subject-detail/begin-set
        let data = []
        console.log('this.data', this.data)
        this.data.forEach((i) => {
          data.push(...i.list)
        })
        console.log('data', data)

        let list = this.mapChildren(data)

        // this.data.forEach((item) => {
        //   item.list.forEach((item2) => {
        //     list.push(item2)
        //     if (item2.children.length != 0) {
        //       item2.children.forEach((chil) => {
        //         list.push({ ...chil, ...{ isis: 1 } })
        //         if (chil.children.length !== 0) {
        //           chil.children.forEach((i) => {
        //             list.push(i)
        //           })
        //         }
        //       })
        //     }
        //   })
        // })
        list.forEach((i) => {
          i.subject_id = i.bill_code
        })
        postAction('/cashAdmin/subject-detail/begin-set', {
          is_check: type,
          data: JSON.stringify(list),
        }).then((r) => {
          this.$message.success(r.msg)
          this.fetchData()
        })
      },
      // handleAdd() {
      //   // 添加
      //   // this.$refs.edit.aname = ''
      //   if (this.selectRow) {
      //     console.log(this.selectRow)
      //     if (this.selectRow.level != 1) {
      //       this.$message.error('请选择一级科目新建子科目')
      //     } else if (this.selectRow.is_child == 1) {
      //       this.$message.error('该科目不允许被添加二级科目')
      //     } else {
      //       // 添加科目
      //       // alert(1)
      //       this.$refs.edit.title = '新增科目/账户'
      //       this.$refs.edit.aname = this.selectRow.name
      //       this.$refs.edit.form.parent_id = this.selectRow.id

      //       this.$refs.edit.showDialog = true
      //     }
      //   } else {
      //     this.$message.error('请选择一项数据')
      //   }
      // },
      // handleEditRow(index, row) {
      //   console.log(row)
      //   this.$refs.edit.title = '编辑科目/账户'
      //   this.$refs.edit.isAdd = false
      //   this.$refs.edit.aname = row.parent_text
      //   this.$refs.edit.form.parent_id = row.id
      //   this.$refs.edit.form.name = row.name
      //   this.$refs.edit.form.depart_id = row.depart_ids
      //   this.$refs.edit.form.bill_code = row.bill_code

      //   this.$refs.edit.showDialog = true
      // },
      // handleDeleteRow(index, row) {
      //   // deleteRow
      //   this.$confirm('确定删除此项吗?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning',
      //   })
      //     .then(() => {
      //       deleteRow({
      //         id: row.id,
      //       }).then((res) => {
      //         if (res.code == 200) {
      //           this.$message.success('删除成功')
      //           this.fetchData()
      //         } else {
      //           this.$message.error(res.msg)
      //         }
      //       })
      //     })
      //     .catch(() => {
      //       console.log('no')
      //     })
      // },
    },
  }
</script>
<style lang="scss" scoped>
  .orderTest-container {
    padding: 20px;
    background: #fff;
    .wrap {
      display: flex;
      justify-content: space-between;
      .left {
        width: 70%;
      }
      .right {
        width: 30%;
        text-align: right;
      }
    }
  }
</style>
<style>
  .blue {
    background: #f8f8ff !important;
  }
</style>
