var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c("div", { staticClass: "wrap" }, [
        _c(
          "div",
          { staticClass: "left" },
          [
            _c(
              "el-button-group",
              _vm._l(_vm.data, function (item, index) {
                return _c(
                  "el-button",
                  {
                    key: index,
                    attrs: {
                      type: _vm.form.type == index + 1 ? "primary" : "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.navClick(item.id)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          ref: "table",
          staticStyle: { "margin-top": "15px" },
          attrs: {
            stripe: "",
            data: _vm.list,
            "default-expand-all": "",
            "row-key": "id",
            "max-height": "600",
            indent: 34,
            "row-class-name": _vm.rowClass,
            "highlight-current-row": "",
            "tree-props": { children: "children", hasChildren: "hasChildren" },
          },
          on: { "current-change": _vm.handleCurrentChange },
        },
        [
          _c(
            "el-table-column",
            { attrs: { align: "center", type: "index", width: "50" } },
            [
              _c(
                "template",
                { slot: "header" },
                [
                  _c(
                    "el-popover",
                    {
                      attrs: {
                        "popper-class": "custom-table-checkbox",
                        trigger: "hover",
                      },
                    },
                    [
                      _c(
                        "el-checkbox-group",
                        {
                          model: {
                            value: _vm.checkList,
                            callback: function ($$v) {
                              _vm.checkList = $$v
                            },
                            expression: "checkList",
                          },
                        },
                        _vm._l(_vm.columns, function (item, index) {
                          return _c("el-checkbox", {
                            key: index,
                            attrs: { label: item.label },
                          })
                        }),
                        1
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { slot: "reference", type: "text" },
                          slot: "reference",
                        },
                        [
                          _c("vab-remix-icon", {
                            attrs: { icon: "settings-line" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._l(_vm.finallyColumns, function (item, tableIndex) {
            return _c("el-table-column", {
              key: tableIndex,
              attrs: {
                prop: item.prop,
                label: item.label,
                width: "",
                type: item.type,
                align: "center",
              },
            })
          }),
          _c(
            "el-table-column",
            { attrs: { prop: "", label: "借贷方向", align: "center" } },
            [_vm._v(" " + _vm._s(_vm.type) + " ")]
          ),
          _c("el-table-column", {
            attrs: { prop: "", label: "期初金额", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    row.is_has_child == 0
                      ? _c(
                          "div",
                          [
                            _c("el-input", {
                              model: {
                                value: row.money,
                                callback: function ($$v) {
                                  _vm.$set(row, "money", $$v)
                                },
                                expression: "row.money",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "dialog-footer" },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  return _vm.handleSunmit(0)
                },
              },
            },
            [_vm._v("暂存")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "warning" },
              on: {
                click: function ($event) {
                  return _vm.handleSunmit(2)
                },
              },
            },
            [_vm._v("试算平衡")]
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.handleSunmit(1)
                },
              },
            },
            [_vm._v("完成建账")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }